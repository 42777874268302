import React from 'react';
import { shape, string, number } from 'prop-types';
import { Image } from 'nordic/image';
import { Thumbnail } from '@andes/thumbnail';

const namespace = 'ui-seller-data';

const ImageLogo = ({ logo }) => {
  const { width, url, action } = logo;

  return action ? (
    <a href={action.target}>
      <Thumbnail
        className={`${namespace}__logo-image`}
        modifier="square"
        size={width}
        srLabel={action.accessibility_text}
      >
        <Image alt="" key={url.src} src={url.src} />
      </Thumbnail>
    </a>
  ) : (
    <Thumbnail className={`${namespace}__logo-image`} modifier="square" size={width}>
      <Image alt="" key={url.src} src={url.src} />
    </Thumbnail>
  );
};

ImageLogo.propTypes = {
  logo: shape({
    width: number,
    url: shape({
      src: string,
    }),
    action: shape({
      target: string,
    }),
  }).isRequired,
};

export default ImageLogo;
