import React from 'react';
import { shape, string } from 'prop-types';
import { StyledLabel } from '@vpp-frontend-components/styled-label';
import { componentEnhance } from '@vpp-frontend-components/component-enhance';

const namespace = 'ui-seller-data-header';

const ExtraInfo = ({ reseller, social_media, products, sales, follow_title }) => (
  <div className={`${namespace}__extra-info-container`}>
    <div className={`${namespace}__extra-info`}>
      {reseller && (
        <div className={`${namespace}__reseller-container`}>
          <StyledLabel
            as="p"
            text={reseller.text}
            color={reseller.color}
            font_size={reseller.font_size}
            className={`${namespace}__reseller non-selectable`}
          />
        </div>
      )}
      <div className={`${namespace}__detail-info-container`}>
        {follow_title && (
          <StyledLabel
            as="p"
            text={componentEnhance.jsx(follow_title.text, follow_title.values)}
            color={follow_title.color}
            font_size={follow_title.font_size}
            className={`${namespace}__followers`}
          />
        )}
        {social_media && (
          <StyledLabel
            as="p"
            text={componentEnhance.jsx(social_media.text, social_media.values)}
            color={social_media.color}
            font_size={social_media.font_size}
            className={`${namespace}__social_media`}
          />
        )}
        {products && (
          <StyledLabel
            as="p"
            text={componentEnhance.jsx(products.text, products.values)}
            color={products.color}
            font_size={products.font_size}
            className={`${namespace}__products`}
          />
        )}
        {sales && (
          <StyledLabel
            as="p"
            text={componentEnhance.jsx(sales.text, sales.values)}
            color={sales.color}
            font_size={sales.font_size}
            className={`${namespace}__sales`}
          />
        )}
      </div>
    </div>
  </div>
);

ExtraInfo.propTypes = {
  reseller: shape({
    text: string,
    color: string,
    font_size: string,
  }),
  social_media: shape({
    text: string,
    color: string,
    font_size: string,
    values: shape({}),
  }),
  products: shape({
    text: string,
    color: string,
    font_size: string,
    values: shape({}),
  }),
  sales: shape({
    text: string,
    color: string,
    font_size: string,
    values: shape({}),
  }),
  follow_title: shape({
    title: shape({
      text: string,
      color: string,
      font_size: string,
      values: shape({}),
    }),
  }),
};

ExtraInfo.defaultProps = {
  reseller: null,
  social_media: null,
  products: null,
  sales: null,
  follow_title: null,
};

export default ExtraInfo;
